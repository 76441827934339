<template>
  <div class="contextual-help t2">
    <div class="contextual-help__tip">
      <Tip back="/" forth="/tutorial3" text="Um Augmented Reality auszulösen, richten Sie Ihre Kamera auf das Bild mit dem H&M& HOME-QR-Code." />
    </div>
    <div class="contextual-help__screen">
      <div class="contextual-help__image contextual-help__image_shade">
        <img src="/images/facade.jpg" srcset="/images/facade@2x.jpg 2x" alt="">
      </div>
      <div class="contextual-help__note">
        <p>Soyez attentifs &agrave;&nbsp;la&nbsp;circulation et&nbsp;aux alentours.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Tip from '@/components/Tip.vue'

export default {
  name: 'Tutorial2',
  components: {
    Tip
  },
  mounted: function() {
    store.set('currentPage', 'Tutorial2');
  }
}
</script>

<style lang="scss">

@import "./../assets/styles/variables.scss";

.t3 {
  background: $background-color;
}

.contextual-help {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contextual-help__screen {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
    height: 101%;
    background-image: url("../assets/images/frame_inner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.6s ease;
  }
}

.contextual-help__image {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease 0.2s;
  transform-origin: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.contextual-help__note {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0 20px 35px;
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  z-index: 1;
  transition: opacity 0.3s ease;

  p {
    max-width: 180px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contextual-help__image_shade {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6)
  }
}
</style>
